const data = {
  blogName: "schmiede.ONE blog",
  footerText: {
    imprint: "imprint",
    about: "about schmiede.ONE",
    trademark: "© schmiede.ONE GmbH & Co. KG 2020",
    dataProtection: "privacy policy",
  },
};

export default data;